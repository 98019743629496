import React, { useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"

import Cart from "./Cart"
import Search from "../Search/Search"
import CartLayout from "../Layout/CartLayout"
import AddMedicineModal from "./Order/AddMedicineModal"
import SpecialRequestModal from "./Order/SpecialRequestModal"
import MedicalSuppliesModal from "../Elements/MedicalSuppliesModal"

import { AppContext } from "../../context/AppContext"
import { useEpharmacyProducts } from "./hooks/useEpharmacyProducts"
import { reformatAirtableData } from "./services/reformatAirtableData"
import { isMedicineAlreadyInCart } from "./services/cart"

const Order = ({ backRoute, nextRoute, location }) => {
  const data = useEpharmacyProducts()
  const { dispatch, state } = useContext(AppContext)
  const { epharmacy } = state
  const HAS_ADDED_MEDICINES = state?.epharmacy?.medicines?.length > 0
  const hasRxRequiredMedicine =
    state?.epharmacy?.medicines?.some((medicine) => !!medicine.rxRequired) ||
    !!epharmacy?.isMedGrocerWellnessPatient ||
    !!epharmacy?.hasPartnerRequest

  const NEXT_ROUTE = {
    LINK: `/epharmacy/${hasRxRequiredMedicine ? "upload" : "checkout"}`,
    LABEL: hasRxRequiredMedicine ? "Upload Rx" : "Checkout",
  }

  const proceedToSearch = () => {
    dispatch({
      type: "HIDE_MODAL",
    })
  }

  const proceedToUpload = () => {
    navigate("/epharmacy/upload")
  }

  const handleSelectSpecialRequest = ({ route }) => {
    let newEpharmacyState = { ...state.epharmacy }
    let specialRoutHeader = "Partner Request"

    if (route === "Partner Request (MedGrocer)")
      newEpharmacyState.hasPartnerRequest = true
    else if (route === "MedGrocer Wellness Patient") {
      newEpharmacyState.isMedGrocerWellnessPatient = true
      specialRoutHeader = "MedGrocer Wellness Patient"
    }

    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: newEpharmacyState,
      },
    })

    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: specialRoutHeader,
        isCard: true,
        headerClass: `has-text-primary has-background-light is-size-5`,
        content: (
          <SpecialRequestModal
            route={route}
            handleContinueSearching={proceedToSearch}
            handleProceedToUpload={proceedToUpload}
          />
        ),
      },
    })
  }

  const handleOnSelect = (item) => {
    let specialRoutes = [
      "Partner Request (MedGrocer)",
      "MedGrocer Wellness Patient",
    ]

    if (
      item?.category === "Medical Supply" &&
      state?.epharmacy?.coupon?.websiteDescription?.includes(
        "Free quarterly coupons for MedGrocer.com"
      )
    ) {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          isCard: true,
          isBodyOnly: true,
          hideCloseButton: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: <MedicalSuppliesModal dispatch={dispatch} />,
        },
      })
    } else {
      if (!item) return
      if (specialRoutes?.includes(item?.productTitle))
        return handleSelectSpecialRequest({ route: item?.productTitle })
      if (isMedicineAlreadyInCart(item, state.epharmacy.medicines))
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message: `${item.productTitle} already in cart`,
            color: "danger",
            isMedToast: true,
          },
        })
      else
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Add medicine to cart",
            isCard: true,
            headerClass: `has-text-primary has-background-light is-size-5`,
            content: (
              <AddMedicineModal addToCart={addMedicineToCart} medicine={item} />
            ),
          },
        })
    }
  }

  const addMedicineToCart = async (item, qty) => {
    const medicineList = [...state.epharmacy.medicines]
    if (!item.molecule) medicineList.push({ ...item, qty: qty })
    else medicineList.push({ ...item, qty: qty })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.molecule || item?.productTitle} to cart`,
        color: "success",
        isMedToast: true,
      },
    })
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    if (location?.state?.selectedMedicine) {
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `Added ${location?.state?.selectedMedicine} to cart`,
          color: "success",
          isMedToast: true,
        },
      })
      dispatch({
        type: "SHOW_CART",
      })
    }
  }, [])

  // transform airtable data
  let medgrocerProducts = data.allAirtableallMedGrocerProducts.nodes.map(
    (node) => node.data
  )
  medgrocerProducts = medgrocerProducts.map(reformatAirtableData)

  const searchComponentProps = {
    placeholder: "Metformin",
    dataSource: {
      type: "json",
      data: medgrocerProducts,
      lookupKeys: ["molecule", "brand", "productTitle"],
    },
    onSelect: handleOnSelect,
    message: null,
  }

  let searchMessage = `We believe that patients should have access to their medicines. If MedGrocer does not have your medicines, you may try <a target="__blank" href="https://southstardrug.com.ph">Southstar</a> or Mercury Drugstore at (02) 87000777 or 09190806426.`

  let isPartnerRequest = epharmacy?.hasPartnerRequest
  let isWellnessPatient = epharmacy?.isMedGrocerWellnessPatient

  let shouldCartBeDisabled =
    !HAS_ADDED_MEDICINES && !isPartnerRequest && !isWellnessPatient
  if (
    epharmacy?.isMedGrocerWellnessPatient &&
    epharmacy?.coupon?.code !== "MGWELL23"
  )
    shouldCartBeDisabled = true

  return (
    <CartLayout
      cartContainer={<Cart open={{ medicinesCard: true }} />}
      desktop={10}
      fullhd={8}
      isCentered
      next={{
        label: NEXT_ROUTE?.LABEL,
        link: NEXT_ROUTE?.LINK,
        className: "is-hidden-desktop",
        disabled: shouldCartBeDisabled,
      }}
      back={{
        label: "Back",
        link: "/",
        className: "is-hidden-desktop",
      }}
    >
      <div className="mt-3">
        <div className="is-hidden-mobile">
          <Search
            {...searchComponentProps}
            message={{ content: searchMessage }}
          />
        </div>
        <div className="is-hidden-desktop is-hidden-tablet">
          <Link to="/epharmacy/search">
            <Search {...searchComponentProps} isMobile />
          </Link>
        </div>
      </div>
    </CartLayout>
  )
}

export default Order
